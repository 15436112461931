import {
    Box,
    ExpandableSection,
    FlashbarProps,
    SpaceBetween,
    StatusIndicator,
    TableProps,
    Tabs,
    TabsProps
} from '@amzn/awsui-components-react';
import {
    DeviceLink,
    EventsTableItem,
    GreenlightEvent,
    KeepDeviceLink,
    PACSDevice,
    ReaderConfigData,
    ISectionEvent,
    ISectionColumn,
    ISectionTableItem
} from 'src/interfaces';
import { GREENLIGHT_DEVICE_NOTES, ONGUARD_COMMAND_KEYPAD_IDS } from 'src/constants';
import { NotificationActionType, useNotifications } from 'src/hooks/notifications';
import React, { useEffect, useState } from 'react';
import { SSMClient, GetParametersCommand } from "@aws-sdk/client-ssm";
import { appBaseState, appEventsBaseState } from 'src/stores/app';
import { Auth } from 'aws-amplify';
import { ConnectionState } from "@aws-amplify/pubsub";
import DeviceCellInfo from './DeviceCellInfo';
import { DeviceSection } from 'src/types';
import { DeviceTable } from './DeviceTable';
import EventCellInfo from './EventCellInfo';
import { useHookstate } from '@hookstate/core';

export default function DeviceTests({connectionState, siteDevices, siteReaderConfigs, keepLinkedSiteDevices, linkedSiteDevices, siteEvents, sections}: {
    connectionState: ConnectionState,
    siteDevices: PACSDevice[],
    keepLinkedSiteDevices: KeepDeviceLink[],
    linkedSiteDevices: DeviceLink[],
    siteReaderConfigs: ReaderConfigData[],
    siteEvents: GreenlightEvent[],
    sections: DeviceSection[]
}) {
    const [sectionEventsBuilt, setSectionEventsBuilt] = useState(false);
    const [trackedEvents, setTrackedEvents] = useState<ISectionEvent | null>(null);
    const [columns, setColumns] = useState<ISectionColumn | null>(null);
    const [tableItems, setTableItems] = useState<ISectionTableItem | null>(null);

    const trackedEventsPulled = React.useRef(false);
    const sectionColumnsBuilt = React.useRef(false);

    const { dispatch } = useNotifications();
    const appEventState = useHookstate(appEventsBaseState);
    const appState = useHookstate(appBaseState);

    /**
     * Grab our tracked events from SSM for our device sections
     */
    const getTrackedEvents = () => {
        if (trackedEventsPulled.current)
            return;

        let newTrackedEvents: ISectionEvent;
        
        Auth.currentCredentials().then(creds => {
            // Setup our SSM client
            const ssm = new SSMClient({
                region: 'us-east-1',
                credentials: Auth.essentialCredentials(creds)
            });

            // Build a list of SSM parameter paths to query for
            let params = new Set<string>();
            sections.forEach(section => {
                // I/O device section uses a joined parameter
                if (section.id == 'inputs_outputs') {
                    params.add(`/greenlight/gamma/config/events/input_output`);
                    return;
                }

                section.device_types.map(type => params.add(`/greenlight/gamma/config/events/${type}`))
            });

            // Query SSM for our tracked events
            const ssmCommand = new GetParametersCommand({
                Names: Array.from(params)
            });

            ssm.send(ssmCommand).then(resp => {
                let ssmEvents: string[];

                if (!resp.Parameters) {
                    let message: FlashbarProps.MessageDefinition = {
                        type: 'error',
                        header: 'Failed to grab info from SSM',
                        content: 'There was an error fetching information from SSM. Refresh the page to try again.'
                    }

                    dispatch({
                        type: NotificationActionType.ADD_NOTIFICATION,
                        message: message
                    });

                    throw new Error(`Parameter response from SSM was undefined for device types\n${JSON.stringify(resp)}`);
                }

                // Populate our tracked events for each section
                sections.forEach(section => {
                    // Filter matching SSM parameters based on section types and param name
                    const sectionParams = resp.Parameters?.filter(param => section.device_types.some(type => param.Name?.includes(type)));
                    // Grab a string list of our joined values
                    const sectionParamValues = sectionParams?.map(param => param.Value).join().split(',');
                    // Exceptions to filter out certain event types from sections
                    const sectionValues = sectionParamValues?.filter(event => {
                        if (section.id == 'command_keypads')
                            return event.includes('Intrusion')

                        if (section.id == 'readers')
                            return !event.includes('Intrusion')

                        return true;
                    });

                    // Set tracked events for the section id
                    if (sectionValues)
                        newTrackedEvents = {...newTrackedEvents, [section.id]: sectionValues}
                });
                
                // Update state
                appEventState.trackedEvents.set(newTrackedEvents);
                setTrackedEvents(newTrackedEvents);
            }).catch(err => {
                let message: FlashbarProps.MessageDefinition = {
                    type: 'error',
                    header: 'Unexpected response from SSM',
                    content: 'There was an unexpected response when fetching parameters from SSM. Refresh the page to try again.'
                }

                dispatch({
                    type: NotificationActionType.ADD_NOTIFICATION,
                    message: message
                });

                throw new Error(`Unexpected SSM response.. ${err}`);
            })
        });
        
        // Mark as pulled to complete step
        trackedEventsPulled.current = true;
    }

    /**
     * Build section table columns
     */
    const buildTableColumns = () => {
        if (sectionColumnsBuilt.current || !trackedEvents)
            return;

        let newColumns: ISectionColumn = {};

        sections.forEach(section => {
            const sectionEvents = trackedEvents[section.id];

            let columns: TableProps.ColumnDefinition<any>[] = sectionEvents.map(trackedEvent => ({
                id: trackedEvent,
                header: trackedEvent,
                cell: (item: EventsTableItem) => (
                <Box padding={{vertical: 'xs'}}>
                    <EventCellInfo
                        deviceID={item.device_id}
                        deviceName={item.device_name}
                        deviceType={item.device_type}
                        eventData={item[`EVENT_${trackedEvent.replace(/\ /g, '_')}`]}
                        linkedDevices={item.linked_device_ids ? item.linked_device_ids : undefined}
                        readerConfig={item.reader_config ? item.reader_config : undefined}
                        trackedEvent={trackedEvent}
                    />
                </Box>),
                sortingField: undefined
            }));

            // Add our device name to table column
            columns.unshift({
                id: 'device_name',
                header: 'Device Name',
                cell: (item: EventsTableItem) => <DeviceCellInfo item={item} sectionId={section.id}/>,
                sortingField: 'device_name'
            });

            // Add parent device for our I/O section
            if (section.id == 'inputs_outputs') { 
                columns.unshift({
                    id: 'parent_device_name',
                    header: 'Parent Device',
                    cell: (item: any) => item.parent_device_name,
                    sortingField: 'parent_device_name',
                })
            }

            newColumns = {...newColumns, [section.id]: columns};
        });

        sectionColumnsBuilt.current = true;
        setColumns(newColumns);
    }

    /**
     * Build table items
     */
    const buildTableItems = () => {
        if (!trackedEvents || !sectionColumnsBuilt.current)
            return;

        let newTableItems: ISectionTableItem = {};
        // Used to set device state for entire site
        let newSiteDevices: EventsTableItem[] = [];

        sections.forEach(section => {
            // Grab only matching device types and events for the section
            let sectionDevices = siteDevices.filter(device => section.device_types.some(
                type => ['input', 'output'].includes(type) 
                ? device.Device_Type.includes(type) 
                : device.Device_Type.toLowerCase() === type
            ));
            let sectionEvents = trackedEvents[section.id];
            // Additional filtering for devices in a section (e.g. removing keypads from readers)
            if (section.id == 'command_keypads')
                sectionDevices = sectionDevices.filter(device => device.Device_Type_Id ? ONGUARD_COMMAND_KEYPAD_IDS.includes(device.Device_Type_Id) : false);
            if (section.id == 'readers')
                sectionDevices = sectionDevices.filter(device => device.Device_Type_Id ? !ONGUARD_COMMAND_KEYPAD_IDS.includes(device.Device_Type_Id) : true);

            // Remove duplicate device IDs from PACS device response
            const dedupe = new Set();
            const devices = sectionDevices.filter(device => {
                let dupe = dedupe.has(device.DeviceID);
                dedupe.add(device.DeviceID);
                return !dupe;
            });

            // Build our list of table items
            const itemList = devices.map(device => {
                let item = {} as EventsTableItem;
                
                item.device_name = section.id != 'inputs_outputs' ? device.Child_DeviceName ?? '' : device.SubChild_DeviceName ?? '';
                item.device_id = device.DeviceID;
                item.parent_device_name = device.Parent_DeviceName;
                item.child_device_name = device.Child_DeviceName;
                item.subchild_device_name = device.SubChild_DeviceName;
                item.device_source = device.devicesource;
                item.device_type = device.Device_Type;
                item.device_type_id = device.Device_Type_Id;
                item.parent_device_id = device.Parent_DeviceID;
                item.child_device_id = device.Child_DeviceID;
                item.subchild_device_id = device.Subchild_DeviceID;
                item.parent_device_name = device.Parent_DeviceName;
                item.expanded = true; // Expand (I/O link) by default
                item.tests_successful = true;
                item.linked_input_id = null;
                item.testers = [];

                // For IO devices, add linked device IDs
                if (section.id == 'inputs_outputs') {
                    try {
                        let linked_device_ids: string[] = [];
                        if (device.devicesource === "keep") {
                            const keepDeviceLinks: KeepDeviceLink[] = keepLinkedSiteDevices as KeepDeviceLink[];
                            if (device.Device_Type.includes('output')) {
                                const deviceLinks: KeepDeviceLink[] = keepDeviceLinks.filter((deviceLink: KeepDeviceLink) => (device.device_href.endsWith(deviceLink.output_key)));
                                deviceLinks.forEach((deviceLink: KeepDeviceLink) => {
                                    const linkedInput: PACSDevice | undefined = devices.find((device: PACSDevice) => (device.device_href.endsWith(deviceLink.input_key)));
                                    if (linkedInput) {
                                        item.linked_input_id = linkedInput.DeviceID;
                                        linked_device_ids.push(linkedInput.DeviceID);
                                    }
                                });
                            } else {
                                const deviceLinks: KeepDeviceLink[] = keepDeviceLinks.filter((deviceLink: KeepDeviceLink) => (device.device_href.endsWith(deviceLink.input_key)));
                                deviceLinks.forEach((deviceLink: KeepDeviceLink) => {
                                    const linkedOutput: PACSDevice | undefined = devices.find((device: PACSDevice) => (device.device_href.endsWith(deviceLink.output_key)));
                                    if (linkedOutput) {
                                        linked_device_ids.push(linkedOutput.DeviceID);
                                    }
                                });
                            }
                        } else {
                            const onguardDeviceLinks: DeviceLink[] = linkedSiteDevices as DeviceLink[];
                            if (device.Device_Type.includes('output')) {
                                const deviceLinks: DeviceLink[] = onguardDeviceLinks.filter((deviceLink: DeviceLink) => (
                                  deviceLink.output_device_parent_id == device.Parent_DeviceID &&
                                  deviceLink.output_device_child_id == device.Child_DeviceID &&
                                  deviceLink.output_device_sub_child_id == device.Subchild_DeviceID
                                ));
                                deviceLinks.forEach((deviceLink: DeviceLink) => {
                                    const input_id = `${deviceLink.input_device_parent_id}_${deviceLink.input_device_child_id}_${deviceLink.input_device_sub_child_id}`
                                    const linkedInput: PACSDevice | undefined = devices.find((device: PACSDevice) => (
                                      device.DeviceID === input_id
                                    ));
                                    if (linkedInput) {
                                        item.linked_input_id = input_id;
                                        linked_device_ids.push(input_id);
                                    }
                                });
                            } else {
                                const deviceLinks = onguardDeviceLinks.filter((deviceLink: DeviceLink) => (
                                  deviceLink.input_device_parent_id == device.Parent_DeviceID &&
                                  deviceLink.input_device_child_id == device.Child_DeviceID &&
                                  deviceLink.input_device_sub_child_id == device.Subchild_DeviceID
                                ));
                                linked_device_ids = deviceLinks.map((deviceLink: DeviceLink) => {
                                    return `${deviceLink.output_device_parent_id}_${deviceLink.output_device_child_id}_${deviceLink.output_device_sub_child_id}`
                                });
                            }
                        }
                        if (linked_device_ids.length > 0) {
                            item.linked_device_ids = linked_device_ids;
                        }
                    } catch (err) {
                        console.error(`Error adding device links`, err);
                    }
                }

                // For readers, add reader config data
                if (section.id == 'readers' && siteReaderConfigs.length > 0) {
                    // Find reader config from matching panel ID
                    const readerConfig = siteReaderConfigs.find(config => (
                        config.PANELID === device.Parent_DeviceID &&
                        config.READERID === device.Child_DeviceID
                    ));

                    item.reader_config = readerConfig;
                }

                // Populate event data for the device
                sectionEvents.forEach(event => {
                    const eventData = siteEvents.find(ev => (device.DeviceID === ev.deviceID && event === ev.eventName)) || {} as GreenlightEvent;

                    // Add testers to device
                    if (eventData.testSubmittedBy && !item.testers.includes(eventData.testSubmittedBy))
                        item.testers.push(eventData.testSubmittedBy)

                    // Verify a successful test
                    if (item.tests_successful && !eventData.isOverridden && !eventData.eventReceivedSuccess) {
                        // Only look at alarm events for inputs
                        if (item.device_type.includes('input') && event.includes("Alarm"))
                            item.tests_successful = false;

                        // Only look at relay contact events for outputs
                        if (item.device_type.includes('output') && event.includes("Relay"))
                            item.tests_successful = false;
                        
                        // Keypads only tests for intrusion commands
                        if (section.id == 'command_keypads' && event.includes("Intrusion"))
                            item.tests_successful = false;

                        // REX is not configured, so consider successful and skip
                        if (event.includes('REX') && item.reader_config === undefined)
                            return;

                        // S/L devices can ignore Door Held/Door Forced Alarm Tests
                        if (item.device_name.includes('S/L') && event.includes('Door'))
                            return;

                        // Exceptions for readers based on their configuration. Consider successful if..
                        if (item.reader_config) {
                            if (item.reader_config.DONOTWAIT === 1 && event === 'Access Granted No Entry Made')
                                return;

                            // Skip door events based on doorcontact config
                            if (event.includes('Door')) {
                                if (Number(item.reader_config.DOORCONTACT_SUPERVISION) < 2)
                                    return;

                                if (item.reader_config.PAIRSLAVE === 1)
                                    return;
                            }

                            if (event.includes('REX')) {
                                if (Number(item.reader_config.REX_SUPERVISION) < 2 ||
                                    item.reader_config.PAIRMASTER === 1 ||
                                    item.reader_config.PAIRSLAVE === 1
                                )
                                    return;
                            }
                        }

                        // Don't count intrusion events for readers. These checks are done after checking config above.
                        if (section.id == 'readers' && !event.includes("Intrusion"))
                            item.tests_successful = false;
                    }

                    item[`EVENT_${event.replace(/\ /g, '_')}`] = {
                        lastEventTS: eventData.lastSuccessfulEventTimestampUTC || null,
                        lastEventReceived: eventData.eventReceivedSuccess || null,
                        rexTestFailed: eventData.rexTestFailed || false,
                        lastSuccessID: eventData.lastSuccessID || null,
                        lastTestID: eventData.lastTestID || null,
                        lastTestTS: eventData.testStartTimestampUTC || null,
                        lastTestEndTS: eventData.testEndTimestampUTC,
                        isCancelled: eventData.isCancelled || false,
                        isOverridden: eventData.isOverridden || false,
                        testSubmittedBy: eventData.testSubmittedBy || null,
                        testCancelledBy: eventData.testCancelledBy || null,
                        testOverriddenBy: eventData.testOverriddenBy || null,
                        previousTestID: eventData.previousTestID || null,
                    }
                });

                // Populate item device notes
                const deviceNotesEvent = siteEvents.find(ev => (device.DeviceID === ev.deviceID && GREENLIGHT_DEVICE_NOTES === ev.eventName));
                if (deviceNotesEvent) {
                    item.device_notes = deviceNotesEvent.deviceNotes;
                }

                // Add item to global list
                newSiteDevices = [...newSiteDevices, item];
                return item;
            });

            // Store our item list for this section
            newTableItems = {...newTableItems, [section.id]: itemList}
        })

        // Set global device states
        const ucSiteDevices = newSiteDevices.filter(d => d.device_name && d.device_name.startsWith('uc_'));
        appEventState.loadedSiteDevices.set(newSiteDevices);
        appEventState.loadedUCDevices.set(ucSiteDevices.length);
        appEventState.loadedProdDevices.set(newSiteDevices.length - ucSiteDevices.length);
        // Set new item lists
        setSectionEventsBuilt(true);
        setTableItems(newTableItems);
    }

    useEffect(() => {
        if (!trackedEventsPulled.current) {
            return getTrackedEvents();
        }

        if (trackedEvents && !sectionColumnsBuilt.current) {
            return buildTableColumns();
        }

        // Rebuild or build our table items
        buildTableItems();

    }, [siteEvents, trackedEvents, columns]);

    // Render our components
    if (sectionEventsBuilt && columns && tableItems) {
        if (appState.preferences.view.value === 'TABS') {
            const sectionTabs:TabsProps.Tab[] = [];

            sections.forEach(section => {
                sectionTabs.push({
                    label: section.header,
                    id: section.id,
                    content: <DeviceTable connectionState={connectionState} tableColumns={columns[section.id]} tableItems={tableItems[section.id]} sectionHeader={section.header} tableVariant={'container'}/>
                })
            })

            return (
                <Tabs
                    variant='default'
                    tabs={sectionTabs}
                />
            )
        } else {
            return (
                <SpaceBetween size='m'>
                    {sections.map(section => {
                        return (
                            <ExpandableSection
                                key={section.id}
                                variant={'container'}
                                defaultExpanded={true}
                                headingTagOverride={'h2'}
                                headerText={
                                    <Box variant='h2' display='inline-block'>
                                        {section.header}
                                    </Box>
                                }
                            >
                                <DeviceTable connectionState={connectionState} tableColumns={columns[section.id]} tableItems={tableItems[section.id]} sectionHeader={section.header} tableVariant={'embedded'}/>
                            </ExpandableSection>
                        )
                    })}
                </SpaceBetween>
            )
        }
    } else {
        return (
            <Box textAlign='center'>
                <StatusIndicator type='loading'>
                    Sorting through device data..
                </StatusIndicator>
            </Box>
        )
    }
}